import { get, map } from 'lodash';
import { SectionStatus } from '../container/profile/components/ProfileItem/ProfileItemConstants';
import { EquifaxResponse, NTDRequest, Verified } from '../models/equifax/equifax';
import { ApplicationProperty, ApplicationsResponse } from '../services/groups/Groups';
import { ApplicationState } from '../store/RootReducers';
import { ApplicantProfile, PostIdentificationFormData } from '../store/state/MyProfileFormState';
import { store } from '../store/Store';
import {
  isAddressDetailsFilled,
  isIdentificationDetailsFilled,
  isPersonalDetailsFilled,
} from './ProfileVerifyHelper';
import moment from 'moment';

export const canShowNTDBanner = (details: ApplicantProfile) => {
  if (
    details &&
    details.identificationDetails &&
    details.identificationDetails.ntdPurchased === true
  )
    return false;
  return true;
};

export const canCompleteNTDBanner = (
  details: ApplicantProfile,
  groupApplications: ApplicationsResponse | undefined,
) => {
  if (
    !groupApplications ||
    (groupApplications &&
      groupApplications.submittedApplicationGroups.groups.length === 0 &&
      groupApplications.draftApplications.agencyWiseApplications.length === 0)
  )
    return false;

  return !!(
    details &&
    isPersonalDetailsFilled(get(details, 'personalDetails')) !== SectionStatus.INCOMPLETE &&
    isAddressDetailsFilled(get(details, 'addressDetails.addresses')) !== SectionStatus.INCOMPLETE &&
    isIdentificationDetailsFilled(get(details, 'identificationDetails')) !==
      SectionStatus.INCOMPLETE
  );
};

export const canAnyApplicantShowNTDBanner = (
  details: ApplicantProfile[],
  groupApplications: ApplicationsResponse | undefined,
) => {
  return details.some((detail) => canShowNTDBanner(detail));
};

export const isExpired = (date: string): boolean => {
  const checkConducted = date;
  const plus30 = moment(checkConducted, 'DD/MM/YYYY').add(30, 'days');
  return moment().isAfter(plus30);
};

export const isVerified = (idDetails?: PostIdentificationFormData | null): Verified => {
  if (idDetails === null || idDetails === undefined) return Verified.NOT_VERIFIED;
  if (idDetails.ntdActive && idDetails.ntdDate) {
    if (isExpired(idDetails.ntdDate)) return Verified.EXPIRED;
    return Verified.VERIFIED;
  } else {
    return Verified.NOT_VERIFIED;
  }
};

export const setNtdPurchasedToTrue = (
  applicationState: ApplicationState,
  userRefId: string,
): ApplicationState => {
  return {
    ...applicationState,
    masterProfile: {
      ...applicationState.masterProfile,
      masterProfileData: {
        ...applicationState.masterProfile.masterProfileData!,
        applicants: applicationState.masterProfile.masterProfileData!.applicants.map((a) =>
          a.refId === userRefId
            ? { ...a, ntdPurchased: true, ntdDate: moment().format('DD/MM/YYYY') }
            : a,
        ),
      },
    },
    myProfileForm: {
      ...applicationState.myProfileForm,
      identificationDetails: {
        ...applicationState.myProfileForm.identificationDetails!,
        ntdPurchased:
          applicationState.myProfileForm.refId === userRefId
            ? true
            : applicationState.myProfileForm.identificationDetails!.ntdPurchased,
        ntdDate:
          applicationState.myProfileForm.refId === userRefId
            ? moment().format('DD/MM/YYYY')
            : applicationState.myProfileForm.identificationDetails!.ntdDate,
      },
    },
    additionalPersonalDetailScreen: {
      ...applicationState.additionalPersonalDetailScreen,
      secondaryApplicantProfiles:
        applicationState.additionalPersonalDetailScreen.secondaryApplicantProfiles.map((s) =>
          s.refId === userRefId
            ? {
                ...s,
                identificationDetails: {
                  ...s.identificationDetails!,
                  ntdPurchased: true,
                  ntdDate: moment().format('DD/MM/YYYY'),
                },
              }
            : s,
        ),
    },
  };
};

export const getProperty = (groupApplications: ApplicationsResponse): ApplicationProperty => {
  if (groupApplications.submittedApplicationGroups.groups.length !== 0) {
    return groupApplications.submittedApplicationGroups.groups[0].agencyWiseApplications[0]
      .application[0].propertyList[0];
  } else {
    return groupApplications.draftApplications.agencyWiseApplications[0].application[0]
      .propertyList[0];
  }
};

export const formNTDRequest = (
  applicantProfile: ApplicantProfile,
  groupApplications: ApplicationsResponse,
): NTDRequest => {
  const { postcode, state, streetName, streetNumber, suburb, unitNumber, country, streetType } =
    applicantProfile.addressDetails!.addresses[0].addressComponents!;
  const property = getProperty(groupApplications);

  return {
    profile: {
      branchCode: property.branch || '',
      agentId: '',
      updateRequired: false,
      refId: applicantProfile.refId!,
      currentAddress: {
        address: applicantProfile.addressDetails!.addresses[0].address!,
        addressComponent: {
          postcode,
          state,
          streetName: streetType ? `${streetName} ${streetType}` : streetName,
          streetNumber,
          suburb,
          unitNumber,
          country,
        },
      },
      rentalAddress: {
        address: property.address,
        addressComponent: {
          postcode: property.postcode,
          state: property.state,
          streetName: property.streetName,
          streetNumber: property.streetNumber,
          suburb: property.suburb,
          unitNumber: property.unitNumber,
          country: property.country,
        },
      },
    },
  };
};

export const ntdRecords = (equifaxResponse: EquifaxResponse) => {
  const { tenantIdentityInformation, summary } = equifaxResponse.data!.consumerTenantCheckBody;

  return [
    {
      label: 'First name: ',
      value: tenantIdentityInformation?.individualName.firstGivenName || '-',
    },
    { label: 'Background check records: ', value: summary.ntdRecordCount || '-' },
    { label: 'Surname: ', value: tenantIdentityInformation?.individualName.familyName || '-' },
    { label: 'Bankrupcy records: ', value: summary.bankruptyRecordCount || '-' },
    {
      label: 'Other given name: ',
      value: tenantIdentityInformation?.individualName.otherGivenName || '-',
    },
    { label: 'Court records: ', value: summary.courtRecordCount || '-' },
    {
      label: 'Drivers licence no: ',
      value: tenantIdentityInformation?.driversLicenceNumber || '-',
    },
    { label: 'Directorship: ', value: summary.directorshipProprietorshipCount || '-' },
    {
      label: 'DOB: ',
      value: tenantIdentityInformation
        ? moment(tenantIdentityInformation.dateOfBirth).format('DD/MM/YYYY')
        : '-',
    },
    { label: 'Disqualified directorship: ', value: summary.disqualifiedDirectorshipCount || '-' },
    { label: 'ID verification: ', value: summary.identityVerificationResult || '-' },
    // { label: 'Verification message: ', value: summary.identityVerificationMessage || '-' },
  ];
};
